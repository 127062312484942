import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const Seo = ({ children, location, description, title, image }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }`);

    return <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
        <html lang='es' />
        <title>{title}</title>
        
        {/* Fav Icons - Commented since these are being set through gatsby-plugin-manifest in gatsby-config.js
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="alternate icon" href="/favicon.ico" />
        */}

        {/* Meta Tags */}
        <meta name='viewport' content='width=device-width,initial-scale=1.0' />
        <meta charSet='utf-8' />
        <meta name='description' content={site.siteMetadata.description} />

        {/* Open Graph */}
        {location && <meta property='og:url' content={location.href} />}
        <meta property='og:image' content={image || '/logo.svg'} />
        <meta property='og:title' content={title} key='ogtitle' />
        <meta 
            property='og:site_name' 
            content={site.siteMetadata.title} 
            key='ogsitename' 
        />
        <meta property='og:description' content={description} key='ogdesc' />
        <meta name="google-site-verification" content="wINZyofzF6wzN_SNgB0Ir52GrbdRzuujdVq-4LAErw0" />        
        { children }
    </Helmet>
}

export default Seo;