import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import React from "react";

const PostCard = ({ frontmatter, excerpt, image }) => (
  <Link to={frontmatter.slug}>
    <MDBCard className="my-2 h-100">
      <GatsbyImage className="rounded text-center" image={image} />
      <MDBCardBody>
        <small className="text-muted">{frontmatter.date}</small>
        <figure className="mb-0 mt-2">
          <p className="fs-5">{frontmatter.title}</p>
          <MDBCardText dangerouslySetInnerHTML={{ __html: excerpt }} />
          <figcaption className="blockquote-footer mb-0 mt-2 d-flex justify-content-end">
            Por {frontmatter.author} en&nbsp;
            <cite title="Categoría">{frontmatter.type}</cite>
          </figcaption>
        </figure>
      </MDBCardBody>
    </MDBCard>
  </Link>
);

export default PostCard;
